import { RouteObject } from 'react-router-dom'
import { FullscreenLoader } from '@/layout/components/elements/FullscreenLoader.tsx'

//import { Home } from './pages/Home'
//import { Page1 } from './pages/Page1'
//import { Page2 } from './pages/Page2withDelay'
//import { Page3 } from './pages/Page3withAnimation'

export const routes: RouteObject[] = [
    {
        hydrateFallbackElement: <FullscreenLoader />,
        children: [
            {
                index: true,
                id: 'root',
                lazy: () => import('@/pages/Home'),
                //element: <Home />,
                path: '/'
            },
            {
                lazy: () => import('@/pages/auth/ForgotPassword'),
                // element: <Page1 />,
                path: '/forgot-password'
            },
            {
                lazy: () => import('@/pages/auth/Register'),
                // element: <Page1 />,
                path: '/register'
            },
            {
                lazy: () => import('@/pages/legal/TermsOfService'),
                // element: <Page1 />,
                path: '/legal/tos'
            },
            {
                lazy: () => import('@/pages/legal/DataPrivacy'),
                // element: <Page1 />,
                path: '/legal/data-privacy'
            },
            {
                lazy: () => import('@/pages/legal/Imprint'),
                // element: <Page1 />,
                path: '/legal/imprint'
            },
            {
                lazy: () => import('@/pages/support/Contact'),
                // element: <Page1 />,
                path: '/contact'
            },

            // ---------------------- DEVELOPMENT MENU
            {
                lazy: () => import('@/pages/dev/Info'),
                //element: <PageTests />,
                path: '/dev/info'
            },
            // ---------------------- DEVELOPMENT MENU - UI
            // ---------------------- DEVELOPMENT MENU - UI / Elements
            {
                lazy: () => import('@/pages/dev/ui/elements/Typography'),
                //element: <PageTests />,
                path: '/dev/ui/elements/typography'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Boxes'),
                //element: <PageTests />,
                path: '/dev/ui/elements/boxes'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Buttons'),
                //element: <PageTests />,
                path: '/dev/ui/elements/buttons'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/ButtonGroups'),
                //element: <PageTests />,
                path: '/dev/ui/elements/buttongroups'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Dropdowns'),
                //element: <PageTests />,
                path: '/dev/ui/elements/dropdowns'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Alerts'),
                //element: <PageTests />,
                path: '/dev/ui/elements/alerts'
            },

            {
                lazy: () => import('@/pages/dev/ui/elements/Skeletons'),
                //element: <PageTests />,
                path: '/dev/ui/elements/skeletons'
            },

            {
                lazy: () => import('@/pages/dev/ui/elements/Tables'),
                //element: <PageTests />,
                path: '/dev/ui/elements/tables'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Offcanvas'),
                //element: <PageTests />,
                path: '/dev/ui/elements/offcanvas'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Accordions'),
                //element: <PageTests />,
                path: '/dev/ui/elements/accordions'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Progress'),
                //element: <PageTests />,
                path: '/dev/ui/elements/progress'
            },
            // ---------------------- DEVELOPMENT MENU - UI / Elements / Forms
            {
                lazy: () => import('@/pages/dev/ui/elements/forms/Inputs'),
                //element: <PageTests />,
                path: '/dev/ui/elements/forms/inputs'
            },
            // ---------------------- DEVELOPMENT MENU - UI / Components
            {
                lazy: () => import('@/pages/dev/ui/components/DynTable'),
                //element: <PageTests />,
                path: '/dev/ui/components/dyntables'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Modals'),
                //element: <PageTests />,
                path: '/dev/ui/components/modals'
            },
            {
                lazy: () => import('@/pages/dev/ui/elements/Toasts'),
                //element: <PageTests />,
                path: '/dev/ui/components/toasts'
            },
            // ---------------------- DEVELOPMENT MENU - UI / Animations
            {
                lazy: () => import('@/pages/dev/ui/animations/Animations'),
                //element: <Page3 />,
                path: '/dev/ui/animations'
            },
            // ---------------------- DEVELOPMENT MENU - UI / Examples
            {
                lazy: () => import('@/pages/dev/ui/layouts/Pure'),
                //element: <PageTests />,
                path: '/dev/ui/layouts/pure'
            },
            // ---------------------- DEVELOPMENT MENU - API
            {
                lazy: () => import('@/pages/dev/api/Query'),
                //element: <PageTests />,
                path: '/dev/api/query'
            },
            // ---------------------- DEVELOPMENT MENU - Examples
            {
                lazy: () => import('@/pages/dev/examples/CreateIssue'),
                //element: <PageTests />,
                path: '/dev/examples/create-issue'
            },
            {
                lazy: () => import('@/pages/dev/examples/GeneralDashboard'),
                // element: <Page1 />,
                path: '/dev/examples/dashboard1'
            },
            {
                lazy: () => import('@/pages/dev/examples/Map'),
                // element: <Page1 />,
                path: '/dev/examples/map'
            },
            // ---------------------- DEVELOPMENT MENU - I18N
            {
                lazy: () => import('@/pages/dev/i18n/I18n'),
                //element: <PageTests />,
                path: '/dev/i18n'
            },
            // ---------------------- DEVELOPMENT MENU - Debug
            {
                lazy: () => import('@/pages/dev/debug/Sentry'),
                //element: <PageTests />,
                path: '/dev/debug/sentry'
            },
            // ---------------------- DEVELOPMENT MENU - Errors
            {
                //element: <Error404 />,
                lazy: () => import('@/pages/errors/Error500'),
                path: '/dev/errors/500'
            },
            // ---------------------- CATCHALL (*)
            {
                //element: <Error404 />,
                lazy: () => import('@/pages/errors/Error404'),
                path: '*'
            }
        ]
    }
]
